@keyframes waves_whatsapp {
    0% {
      transform: scale(0.5);
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: scale(1.2);
      opacity: 0;
    }
  }
  
  #main-cta-whatsapp {
    position: fixed;
    right: 35px;
    bottom: 120px;
    z-index: 30;
  
    @media (max-width: 1140px) {
      bottom: 110px;
    }
  
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: -3px;
      right: -3px;
      bottom: -3px;
      left: -3px;
      border: 2px solid #3fc350;
      border-radius: 50%;
      transition-property: border-color;
      transition-duration: time(fast);
      transition-timing-function: ease(inout);
      animation-name: waves_whatsapp;
      animation-duration: 1.5s;
      animation-timing-function: ease(inout);
      animation-iteration-count: infinite;
      z-index: 1;
    }
  
    &::after {
      animation-delay: 0.5s;
    }
  
    a {
      background-color: #3fc350;
      width: 58px;
      height: 58px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      transition-property: background-color, box-shadow;
      transition-duration: time(fast);
      transition-timing-function: ease(inout);
      z-index: 2;
  
      @media (max-width: 1140px) {
        width: 48px;
        height: 48px;
      }
  
      img {
        width: 35px;
  
        @media (max-width: 1140px) {
          width: 30px;
        }
      }
    }
  
    &:hover {
      &::before,
      &::after {
        border-color: lighten(#3fc350, 10%);
      }
  
      a {
        background-color: lighten(#3fc350, 10%);
        box-shadow: 0 5px 50px fade-out(black, 0.8);
      }
    }
  }
  