@import 'variables';

.section-products {
  padding: 110px 0 150px;
  overflow: hidden;

  /** Mobile. */
  @media (max-width: 1023px) {
    padding: 45px 0 50px;
  }

  .section-title {
    color: #3c3c3c;
    font-size: 35px;
    font-weight: bold;
    margin-bottom: 70px;
    text-align: center;

    /** Mobile. */
    @media (max-width: 1023px) {
      display: none;
    }
  }

  .flex-wrapper {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width:100%;
    margin:auto;

    /** Mobile. */
    @media (max-width: 1023px) {
      justify-content: flex-start;
    }

    /** Mobile. */
    @media (max-width: 1023px) {
      align-items: flex-start;
      position: relative;
      width: 100vw;
      left: -15px;
      height: 370px;
      padding-top: 40px;
      padding-left: 5px;
    }
    @media (max-width: 355px) {
      height: 385px;
    }

    &::before {
      content: '';
      background-color: #f5f5f5;
      position: absolute;
      top: -40px;
      bottom: 20px;
      left: 320px;
      width: 100vw;
      z-index: 1;

      /** Mobile. */
      @media (max-width: 1023px) {
        left: 40px;
      }
    }

    .count {
      position: relative;
      z-index: 2;
      margin-right: 15px;
      flex-shrink: 0;

      /** Mobile. */
      @media (max-width: 1023px) {
        z-index: 3;
      }

      ul {
        display: flex;
        flex-direction: column;
        align-items: center;

        li {
          &:not(:last-child) {
            margin-top: 30px;

            /** Mobile. */
            @media (max-width: 1023px) {
              margin-top: 25px;
            }
          }

          &:nth-child(1) {
            order: 5;
          }
          &:nth-child(2) {
            order: 4;
          }
          &:nth-child(3) {
            order: 3;
          }
          &:nth-child(4) {
            order: 2;
          }
          &:nth-child(5) {
            order: 1;
          }

          button {
            background-color: transparent;
            color: #cccccc;
            font-size: 18px;
            font-weight: 600;
            border: none;
            display: inline-block;
            transform: rotate(-90deg);
            transition: color .7s cubic-bezier(.5,0,0,1);

            /** Mobile. */
            @media (max-width: 1023px) {
              color: white;
              font-size: 10px;
              transition: opacity .7s cubic-bezier(.5,0,0,1);
            }
          }

          &:hover button,
          &.active button {
            color: #666;

            /** Mobile. */
            @media (max-width: 1023px) {
              color: white;
              opacity: 0.6;
            }
          }
        }
      }
    }

    .images {
      position: relative;
      width: 100%;
      height: 620px;
      z-index: 2;
      margin: auto;

      /** Mobile. */
      @media (max-width: 1023px) {
        background-color: black;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 370px;
      }
      @media (max-width: 355px) {
        height: 385px;
      }

      .image {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        opacity: 0;
        transition: opacity .7s cubic-bezier(.5,0,0,1);

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        &.active {
          opacity: 1;
          z-index: 2;

          /** Mobile. */
          @media (max-width: 1023px) {
            opacity: 0.7;
          }
        }
      }
    }

    .tabs {
      background-color: white;
      position: absolute;
      bottom: 50%;
      transform: translateY(50%);
      right: 0;
      z-index: 5;
      width: 500px;
      height: 250px;

      /** Mobile. */
      @media (max-width: 1100px) {
        right: 0;
        bottom: 20px;
      }
      @media (max-width: 1023px) {
        background-color: hsla(0,0%,100%,.8);
        top: -100px;
        right: unset;
        bottom: unset;
        left: 30px;
        width: calc(100vw - 30px);
        height: 300px;
      }
      @media (max-width: 355px) {
        height: 310px;
      }

      .tab-content {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        visibility: hidden;
        transition: opacity .7s cubic-bezier(.5,0,0,1),
          visibility .7s cubic-bezier(.5,0,0,1);
        padding: 30px 20px;

        @media (max-width: 1023px) {
          top: 50%;
          transform: translateY(-50%);
        }

        &.active {
          opacity: 1;
          visibility: visible;
        }

        &--title {
          color: #3c3c3c;
          font-size: 20px;
          font-weight: bold;
          letter-spacing: -0.75px;

          /** Mobile. */
          @media (max-width: 1023px) {
            font-size: 16px;
            line-height: 1.5;
          }
        }

        &--description {
          margin-top: 20px;

          /** Mobile. */
          @media (max-width: 1023px) {
            margin-top: 15px;
          }

          p {
            color: #666;
            font-size: 16px;
            font-weight: normal;
            line-height: 1.7;

            /** Mobile. */
            @media (max-width: 1023px) {
              font-size: 14px;
            }
            @media (max-width: 355px) {
              font-size: 12px;
            }
          }
        }

        &--cta {
          margin-top: 25px;

          /** Mobile. */
          @media (max-width: 1023px) {
            margin-top: 15px;
          }

          a {
            color: $color-primary;
            font-size: 14px;
            font-weight: 600;
            text-transform: uppercase;
            transition: color .7s cubic-bezier(.5,0,0,1);

            /** Mobile. */
            @media (max-width: 1023px) {
              font-size: 12px;
            }

            &:hover {
              color: black;
            }
          }
        }
      }

      .arrows {
        display: flex;
        position: absolute;
        right: 0;
        bottom: 0;

        button {
          background-color: $color-primary;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          width: 75px;
          height: 75px;
          transition: background-color .7s cubic-bezier(.5,0,0,1);

          /** Mobile. */
          @media (max-width: 1023px) {
            width: 60px;
            height: 60px;
          }

          &:nth-child(1) {
            &::after {
              content: '';
              background-color: #b34a19;
              position: absolute;
              top: 50%;
              right: 0;
              width: 1px;
              height: 50px;
              transform: translateY(-50%);
            }
          }

          /** Desktop. */
          @media (min-width: 1024px) {
            &:hover {
              background-color: darken($color-primary, 10%);
            }
          }
        }
      }
    }
  }
}