@import 'variables','reset', 'mixins';
@import 'main-whatsapp';

html{        
  font-size:16px; 
}  
body{
  position: relative;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  color: #666;
  background: url('../images/bg-site.svg') #fff center 95px no-repeat;
  @include lg-down{
    background:#FFF;
  }
  background-size: 1250px;
  &.shadow {
    width: 100%;
    overflow: hidden;

    &::before {
      content: "";
      opacity: 0.6;
      background: #000;
      pointer-events: initial;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 99;
    }
  }
}

.container{
  width: 100%;
  max-width: 1200px;
  padding: 0 $gutter;
  margin:0 auto;
}