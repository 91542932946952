@import 'variables', 'mixins';

#main-header {
  background-color: $color-primary;

  z-index: 50;

  .flex-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 115px;
    height: 65px;

    @media (max-width: 1200px) {
      padding: 0;
    }

    .logo a {
      display: inline-block;

      img {
        width: 170px;
      }

      /** Mobile. */
      @media (max-width: 1023px) {
        img {
          width: 150px;
          height: auto;
        }
      }
    }

    .menu-toggler {
      display: none;

      /** Mobile. */
      @media (max-width: 1023px) {
        display: block;
      }
    }

    .right {
      display: flex;
      align-items: center;

      /** Mobile. */
      @media (max-width: 1023px) {
        display: none;
      }

      .navigation {
        position: relative;
        top: -1px;
        margin-right: 45px;

        > ul {
          display: flex;
          align-items: center;

          > li {
            &:not(:last-child) {
              margin-right: 30px;
            }

            span {
              color: white;
              font-size: 14px;
              font-weight: normal;
              display: inline-block;
            }

            > a {
              color: white;
              font-size: 14px;
              font-weight: normal;
              padding-bottom: 10px;
              position: relative;

              &::after {
                content: '';
                background-color: white;
                position: absolute;
                right: 0;
                bottom: 0;
                left: 0;
                height: 2px;
                transform-origin: 50% 50%;
                transform: scaleX(0);
                transition: transform .7s cubic-bezier(.5,0,0,1);
              }

              &:hover {
                &::after {
                  transform: scaleX(1);
                }
              }
            }

            &.has-children {
              position: relative;
              display: flex;
              align-items: center;
              bottom: -2px;

              img {
                display: inline-block;
                margin-left: 10px;
              }

              .submenu {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                opacity: 0;
                visibility: hidden;
                transform: translateY(10px);
                transition-property: opacity,visibility,transform;
                transition-duration: .7s;
                transition-timing-function: cubic-bezier(.5,0,0,1);
                display: flex;
                justify-content: center;
                pointer-events: none;
                padding-top: 30px;

                ul {
                  background-color: #f5f5f5;
                  white-space: nowrap;
                  position: relative;
                  border-radius: 5px;

                  &::before {
                    content: '';
                    position: absolute;
                    top: -7px;
                    left: calc(50% - 6px);
                    width: 1px;
                    height: 1px;
                    border-right: 6px solid transparent;
                    border-bottom: 6px solid white;
                    border-left: 6px solid transparent;
                  }

                  li {
                    white-space: nowrap;

                    a {
                      color: #666;
                      font-size: 14px;
                      font-weight: normal;
                      display: block;
                      height: 38px;
                      display: flex;
                      align-items: center;
                      padding: 1px 40px 0 15px;
                      transition-property: color,background-color;
                      transition-duration: .7s;
                      transition-timing-function: cubic-bezier(.5,0,0,1);

                      &:hover {
                        background-color: rgba(242, 101, 34, 0.1);
                        color: color(grey, dark);
                      }
                    }
                  }
                }
              }

              &:hover .submenu {
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
                pointer-events: initial;
              }
            }
          }
        }
      }

      .phones {
        display: flex;
        align-items: center;

        img {
          display: inline-block;
        }

        a {
          color: white;
          font-size: 14px;
          font-weight: normal;
          padding: 0 10px;
          transition: opacity .7s cubic-bezier(.5,0,0,1);

          &:nth-child(2) {
            border-right: 1px solid white;
          }

          &:hover {
            opacity: 0.6;
          }
        }
      }
    }
  }
}

#main-cookie-policy {
  position: fixed;
  right: 65px;
  bottom: 20px;
  left: 65px;
  z-index: 25;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition-property: opacity, visibility;
  transition-duration: time(default);
  transition-timing-function: ease(inout);

  &.fixed {
    position: absolute;
    display: block;
    bottom: unset;
  }

  @media (max-width: 1023px) {
    right: unset;
    left: 50%;
    transform: translateX(-50%);
    width: 350px;
  }
  @media (max-width: 355px) {
    width: 300px;
  }

  &.active {
    opacity: 1;
    visibility: visible;
    pointer-events: inherit;
  }

  .cookie-content {
    background-color: white;
    display: flex;
    align-items: center;
    padding: 15px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 4px;

    @media (max-width: 1023px) {
      flex-wrap: wrap;
      justify-content: center;
    }

    &--icon {
      position: absolute;
      top: -11px;
      left: -11px;
    }

    &--description {
      flex-grow: 1;

      p {
        color: color(grey, dark);
        font-size: 16px;
        font-weight: normal;
        line-height: 1.6;
        letter-spacing: -0.01em;

        a {
          color: color(orange);
          transition-property: color;
          transition-duration: time(default);
          transition-timing-function: ease(inout);

          &:hover {
            color: color(grey, dark);
          }
        }
      }
    }

    &--cta {
      flex-shrink: 0;
      margin-left: 15px;

      @media (max-width: 1023px) {
        margin: 15px 0 0;
      }
    }
  }
}


.arrow-top {
  position: absolute;
  /*bottom: -20px;*/
  /** Mobile. */
  margin-top: 189px;
  @media (max-width: 767px) {
    margin-top: 20px;
  }  
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
}

.banner{
  background: url('../images/bg-banner.png') no-repeat top center;
  background-size: cover;
  padding: 176px 0 208px;
 
  h1{
    color: #3C3C3C;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    margin: 0;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    padding-left: 70px;
    width: 50%;
    line-height: 65px;
    @include fontSize(36);
  }
  @include md-down{
    padding: 70px 0 40px;
    h1{
      padding: 0;
      @include fontSize(24);
      line-height: 40px;
    }
  }
  @include sm-down{
    h1{
      line-height: 30px;
      @include fontSize(18);
    }
  }
}