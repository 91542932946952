@import 'mixins';

#section-insurance{
  .title{
    font-size: 35px;
    margin-top: 70px;
    margin-bottom: 70px;
    text-align:center;
    color:#3C3C3C;
    @media (max-width: 1023px) {
      text-align: left;
      font-size: 20px;
    }
  }
  .insurance-item{
    margin-bottom: 70px;
    >div>div{
      position: relative;
      &:last-child{
        padding:0 70px;
      }
      @include md-down{
        &:first-child{
          margin-top: 15px;
          order:2
        }
        &:last-child{
          order:1;
          padding: 0 15px;
        }
      }
    }
    img{
      width: 100%;
    }
    .title{
      margin-top: 0;
      margin-bottom: 40px;
      text-align: left;
      @include md-down{
        font-size:25px
      }
      @media (max-width: 1023px) {
        font-size: 20px;
      }
    }
    .desc {
      p{

        line-height: 29px;
      }
    }
    .btn-primary{
      font-weight: 400;
      @include md-up{
        position: absolute;
        bottom: -40px;
        left: -25px;
        &.right{
          left: auto;
          right: -25px;
        }
      }
      @include md-down{
        justify-content: flex-end;
        margin-top: -2px;
        padding: 20px;
      }
      img{
        max-width: 6px;
        margin-left: 25px;
      }
    }
    
    @include md-up{
      .right{
        >div{
          &:first-child{
            order:2
          }
          &:last-child{
            order:1
          }
        }
      }
    }
    
  }
}