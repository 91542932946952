#main-menu {
  background-color: fade-out(black, 0.4);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  transition-property: opacity,visibility;
  transition-duration: .7s;
  transition-timing-function: cubic-bezier(.5,0,0,1);

  .close {
    position: absolute;
    top: 30px;
    right: 30px;
    z-index: 2;
    opacity: 0;
    transition: opacity .7s cubic-bezier(.5,0,0,1);
  }

  .wrapper {
    background-color: white;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 220px;
    border-radius: 20px 0 0 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 50px 0 0 30px;
    transform: translateX(100%);
    transition: transform .7s cubic-bezier(.5,0,0,1);

    .navigation {
      > ul {
        > li {
          position: relative;

          &:not(:last-child) {
            margin-bottom: 30px;
          }

          &.active {
            &::before {
              content: '';
              background-color: color(orange);
              position: absolute;
              top: 50%;
              bottom: 0;
              right: calc(100% + 12px);
              z-index: 1;
              width: 4px;
              height: 30px;
              transform: translateY(-50%);
              display: none;
            }
          }

          a,
          span {
            color: #333333;
            font-size: 16px;
            font-weight: normal;
          }

          ul {
            padding: 15px 15px 0 15px;

            li {
              &:not(:last-child) {
                margin-bottom: 10px;
              }

              a {
                font-size: 12px;
                line-height: 1.5;
              }
            }
          }
        }
      }
    }

    .phones {
      margin-top: 20px;
      padding-right: 15px;

      .phone {
        &:not(:last-child) {
          padding-bottom: 12px;
          margin-bottom: 12px;
          border-bottom: 1px solid fade-out(black, 0.9);
        }

        a {
          color: #7d7d7d;
          font-size: 16px;
          font-weight: normal;
          display: flex;
          align-items: center;

          img {
            position: relative;
            top: 0;
            margin-right: 10px;
          }
        }
      }
    }
  }

  &.active {
    opacity: 1;
    visibility: visible;
    pointer-events: initial;

    .close {
      opacity: 1;
      transition-delay: 0.3s;
    }

    .wrapper {
      transform: translateX(0);
    }
  }
}
