@import 'variables';

.button {
  color: white;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 13px 40px;
  border-radius: 50px;
  transition: background-position .7s cubic-bezier(.5,0,0,1);

  /** Mobile. */
  @media (max-width: 767px) {
    font-size: 14px;
  }

  &-orange {
    background: linear-gradient(
      to right,
      $color-primary 0%,
      #f58c00 33%,
      #f58c00 66%,
      $color-primary 100%
    );
    background-size: 300% 100%;
    background-position: left center;
  }

  &:hover {
    background-position: right center;
  }
}

.btn{
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.3px;
  padding: 27px 70px;
  color:#FFF;
  display: flex;
  align-items: center;
  transition: background-color .7s cubic-bezier(.5,0,0,1);

  &-primary{
    background: $color-primary;
    

    &:hover{
      background: darken($color-primary,10%);
    }
  }
}