@import 'variables', 'mixins';

footer{
  background: $color-primary;
  padding: 65px 0;
  color: #FFF;
  @include sm-down{
    padding: $gutter*3 0;
  }
  a{
    color:#FFF;
    line-height: 24px;
    text-decoration: none;
  }
  h3{
    @include fontSize(16);
    margin: 0 0 10px;
    text-transform: uppercase;
  }
  .container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include xl-down{
      justify-content: flex-start;
    }
    @include sm-down{
      flex-direction: column;
    }
    >div{
      padding: 0 $gutter;
      @include sm-down{
        padding: $gutter;
      }
    }
  }
  .logo{
    width: 170px;
  }

  .social-container{
    .social-list{
      list-style: none;
      padding: 0;
      display: flex;
      align-items: center;
      margin: 45px -5px 0;
      li{
        margin: 5px;
        a{
          background: rgba(255, 255, 255, 0.16);
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          @include addEase(background-color, .7s, cubic-bezier(.5,0,0,1));
          &:hover{
            background-color: #3c3c3c;
          }
        }
      }
    }
  }
  .sac-container{
    h3{
      @include sm-up{
        margin-left: 29px;
      }
    }
    .sac-list{
      list-style: none;
      padding-left: 28px;
      li{
        position: relative;
        margin-bottom: $gutter;
        &:before{
          content:"";
          width: 24px;
          height: 24px;
          position: absolute;
          left: -29px;
          top: 3px;
          background: url('../images/ico-phone.svg') no-repeat center;
        }
        &.marker{
          &:before{
            background-image: url('../images/ico-marker.svg');
          }
        }
      }
    }
  }
  .siteMap{
    display: flex;
    @include xl-down{
      margin-top: $gutter;
      width: 100%;
    }
    @include sm-down{
      margin-top: 0;
      flex-direction: column;
    }
    ul{
      list-style: none;
      padding-left: 0;
      li{
        margin-bottom: 5px;
      }
    }
    >div:not(:last-child){
      margin-right: $gutter*3;
    }
  }
  
}