@import 'variables', 'button','mixins';

form {
  .field-group {
    position: relative;
    margin-top: 40px;

    &::after {
      content: '';
      background-color: #999999;
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 1px;
      transform-origin: 0% 50%;
      transform: scaleX(0);
      transition-property: background-color, transform;
      transition-duration: .7s;
      transition-timing-function: cubic-bezier(.5,0,0,1);
    }

    img {
      position: absolute;
      top: 0;
      right: 5px;
      pointer-events: none;
    }

    .label {
      position: absolute;
      left: 12px;
      font-weight: normal;
      display: block;
      transition-duration: .7s;
      transition-timing-function: cubic-bezier(.5,0,0,1);

      @media (max-width: 1023px) {
        left: 0;
      }
    }

    .error {
      background-color: red;
      color: white;
      font-size: 14px;
      font-weight: 900;
      text-align: center;
      position: absolute;
      top: 50%;
      padding: 0 15px;
      white-space: nowrap;
      height: 30px;
      left: calc(100% + 15px);
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      visibility: hidden;
      transform: translate(10px, -50%);
      transition-property: opacity, visibility, transform;
      transition-duration: .7s;
      transition-timing-function: cubic-bezier(.5,0,0,1);

      &.active {
        opacity: 1;
        visibility: visible;
        transform: translate(0, -50%);
      }

      &::before {
        content: '';
        position: absolute;
        top: 8px;
        left: -5px;
        width: 1px;
        height: 1px;
        border-top: 5px solid transparent;
        border-right: 5px solid red;
        border-bottom: 5px solid transparent;
      }
    }

    .accept-cookies {
      p {
        color: #3c3c3c;
        font-size: 16px;
        font-weight: normal;
        line-height: 1.6;
        letter-spacing: -0.01em;

        a {
          color: $color-primary;
          transition-property: color;
          transition-duration: .7s;
          transition-timing-function: cubic-bezier(.5,0,0,1);

          &:hover {
            color: #3c3c3c;
          }
        }
      }
    }

    &[data-type='text'] {
      .label {
        top: 2px;
        color: #3c3c3c;
        font-size: 14px;
        transform-origin: 0% 0%;
        transition-property: transform;
        pointer-events: none;

        @media (max-width: 1023px) {
          font-size: 12px;
          top: 3px;
        }
      }

      .field {
        color: #999999;
        font-size: 14px;
        font-weight: normal;
        display: block;
        width: 100%;
        border: none;
        border-bottom: 1px solid #999999;
        padding: 0 12px 12px;

        @media (max-width: 1023px) {
          padding: 0 0 12px;
        }
      }

      &.focus,
      &.not-empty {
        &::after {
          transform: scaleX(1);
        }

        .label {
          transform: translateY(-20px) scale(0.79);
        }
      }

      &.focus {
        &::after {
          background-color: $color-primary;
        }
      }

      &.invalid {
        &::after {
          transform: scaleX(1);
          background-color: red;
        }
      }
    }

    &[data-type='file'] {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .placeholder {
        color: #3c3c3c;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        line-height: 1.4;
        letter-spacing: 1px;
        display: block;
        margin-bottom: 12px;
      }

      .field {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        visibility: hidden;
        width: 1px;
        height: 1px;
        overflow: hidden;
      }

      label {
        background-color: color(grey, light);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 1px 20px 0;
        height: 32px;
        cursor: pointer;
        border-radius: 5px;
        overflow: hidden;
        transition-property: background-color;
        transition-duration: .7s;
        transition-timing-function: cubic-bezier(.5,0,0,1);

        span {
          color: #999999;
          font-size: 12px;
          font-weight: 600;
          display: inline-block;
          text-transform: uppercase;
          letter-spacing: 2px;
          cursor: pointer;
          transition-property: color;
          transition-duration: .7s;
          transition-timing-function: cubic-bezier(.5,0,0,1);
        }

        &:hover {
          background-color: #3c3c3c;

          span {
            color: white;
          }
        }
      }

      .filename {
        color: #3c3c3c;
        font-size: 10px;
        font-weight: 600;
        margin-top: 5px;
      }
    }

    &[data-type='options'] {
      .placeholder {
        font-size: 18px;
        font-weight: normal;

        @media (max-width: 1023px) {
          font-size: 12px;
        }
      }

      .options {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 15px;

        .option {
          &:not(:last-child) {
            margin-bottom: 13px;
          }

          button {
            background-color: transparent;
            color: #3c3c3c;
            font-size: 16px;
            font-weight: normal;
            position: relative;
            outline: none;
            padding-left: 25px;

            @media (max-width: 1023px) {
              font-size: 12px;
            }

            &::before,
            &::after {
              content: '';
              position: absolute;
              border-radius: 50%;
              transition-property: opacity, transform, border-color;
              transition-duration: .7s;
              transition-timing-function: cubic-bezier(.5,0,0,1);
            }

            &::before {
              top: -1px;
              left: 0;
              width: 14px;
              height: 14px;
              border: 2px solid rgba(153, 153, 153, 0.5);

              @media (max-width: 1023px) {
                top: -4px;
              }
            }

            &::after {
              background-color: $color-primary;
              top: 4px;
              left: 5px;
              width: 8px;
              height: 8px;
              opacity: 0;
              transform-origin: 50% 50%;
              transform: scale(0);

              @media (max-width: 1023px) {
                top: 1px;
              }
            }

            &:hover {
              &::before {
                border-color: $color-primary;
              }
            }
          }

          &.active button {
            &::before {
              border-color: $color-primary;
            }

            &::after {
              opacity: 1;
              transform: scale(1);
            }
          }
        }
      }
    }

    &[data-type='select'] {
      width: 285px;
      flex-shrink: 0;

      .label {
        top: -20px;
        color: #3c3c3c;
        font-size: 11px;
        opacity: 0;
        transition-property: opacity;

        @media (max-width: 1023px) {
          font-size: 10px;
        }
      }

      .select {
        position: relative;

        &-box {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          border-bottom: 1px solid #999999;
          padding: 0 12px 12px;

          @media (max-width: 1023px) {
            padding: 0 0 12px;
          }

          span {
            color: #3c3c3c;
            font-size: 14px;
            font-weight: normal;
            display: inline-block;

            @media (max-width: 1023px) {
              font-size: 12px;
            }
          }

          img {
            display: inline-block;
            transition-property: transform;
            transition-duration: .7s;
            transition-timing-function: cubic-bezier(.5,0,0,1);
          }
        }

        &-dropdown {
          position: absolute;
          top: 100%;
          left: 0;
          right: 0;
          z-index: 2;
          opacity: 0;
          visibility: hidden;
          transform: translateY(10px);
          transition-property: opacity, visibility, transform, box-shadow;
          transition-duration: .7s;
          transition-timing-function: cubic-bezier(.5,0,0,1);
          max-height: 230px;
          overflow: auto;

          ul {
            display: block;

            li {
              display: block;
              width: 100%;

              button {
                color: #3c3c3c;
                font-size: 16px;
                font-weight: 600;
                width: 100%;
                text-align: left;
                padding: 14px 13px;
                background-color: color(grey, light);
                transition-property: background-color, color;
                transition-duration: .7s;
                transition-timing-function: cubic-bezier(.5,0,0,1);

                &:hover {
                  background-color: #3c3c3c;
                  color: white;
                }
              }
            }
          }
        }
      }

      &.active .select {
        &-box img {
          transform: rotate(180deg);
        }

        &-dropdown {
          opacity: 1;
          visibility: visible;
          transform: translateY(0);
          box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
        }
      }

      &.not-empty {
        .label {
          opacity: 1;
        }
      }

      &.active,
      &.not-empty {
        &::after {
          transform: scaleX(1);
        }
      }

      &.invalid {
        &::after {
          transform: scaleX(1);
          background-color: red;
        }
      }
    }

    @media (max-width: 375px) {
      width: 100% !important;
    }
  }

  .form-submit {
    transition-property: opacity;
    transition-duration: .7s;
    transition-timing-function: cubic-bezier(.5,0,0,1);

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}

.row{
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  align-items: center;

  >div{
    flex: 1 0 100%;
      max-width: 100%;
      padding: 0 15px;
  }
 
  @include md-up {
    .col-md-6{
      flex: 1 0 50%;
      max-width: 50%;
    }
  }

  &.bigSpacing{
    margin: 0 -60px;
    >div{
      padding: 0 60px;
    }
  }
}

.d-none {
  display: none;
}
@include md-up {
  .d-md-block{
    display: block; 
  }
}