#section-contact{
  margin-bottom: 40px;
  overflow: hidden;
  .title{
    color: #3C3C3C;
    font-size: 2rem;
    line-height: 40px;
    text-align: left;
    @media (max-width: 1023px) {
      font-size: 20px;
    }
  }
  .main-content{
    display: flex;
  
    .left{
      @media (max-width: 1023px) {
        width: 100%;
        order: 2;
      }
    }
    .right{
      @media (max-width: 1023px) {
        width: 100%;
        order: 1;
      }
    }
  }
  .container-bg{
    position: relative;
    &:after{
      content:"";
      position:absolute;
      top: 30px;
      bottom: 30px;
      left: 100%;
      width: 100%;
      background: #F5F5F5;
      z-index: 0;      
    }
    
    img{
      max-width: 100%;
      z-index: 1;      
    }
  }
  .row{
    >div:first-child{
      z-index: 1;
    }
  }
}